<script>
import "./plugins/datepicker.css"
import "./plugins/datepicker.js"

export default {
  props: {
    dateStart: Date,
    value: String,
    dateType: String,
    config: Object,
    valueFormat: {default:"YYYY-MM-DD"}		
  },
  computed:{
    dateFormat(){
      if(this.pluginOptions.format=="mm/dd/yyyy") return "MM/DD/YYYY"
      return this.valueFormat
    },
    pluginOptions(){
      var option = {
        todayHighlight: true,
        autoclose: true,
        orientation: "bottom",
        format: "mm/dd/yyyy",
      }
      if(this.dateType=="dob") option = {
        orientation: "bottom",
        format: "mm/dd/yyyy",
        calendarWeeks: true,
        autoclose: true,
        todayHighlight: true,
        startView: 'decade',
        endDate: '-19y',
      }
      return Object.assign(option,this.config||{})
    },
  },
  mounted: function () {
    this.init()
  },
  methods:{
    init(){
      if(this.value) this.$emit('input', this.value.dates(this.dateFormat))
      $(this.$el).datepicker(this.pluginOptions).on('change blur',(e)=>{
        var val = e.target.value.dates(this.valueFormat, this.dateFormat)
        this.$emit('input', val)
        if($(this.$el).closest("form").length) $(this.$el).valid()
      });
      if(this.value) $(this.$el).datepicker("update", this.value.dates(this.dateFormat))
    },
  },
  watch: {
    value(v){
      if(!v) return $(this.$el).datepicker("update", "")
      this.$emit('input', v.dates(this.valueFormat, this.dateFormat))
      $(this.$el).datepicker("update", v.dates(this.dateFormat))
    },
  }
};
</script>

<template>
  <input type="text" class="form-control" autocomplete="off">
</template>
